import React, {useEffect} from 'react'
import { navigate } from 'gatsby-link'
import { Link } from 'gatsby'
import SEO from "../components/seo"
import Layout from "../components/layout"
import Background from "../images/checkout.jpg"
import { TextField, Checkbox } from '@material-ui/core';
import { withStyles, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
const theme = createMuiTheme({
  palette: {
    primary: { 500: '#7176ff' },
  },
});


const CssTextField = withStyles({
  root: {
    '& label.MuiFormLabel-root': {
      color: '#d3d4fc'
    },
    '& label.Mui-focused': {
      color: '#7176ff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#7176ff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(211,212,252,0.7)',
      },
      '&:hover fieldset': {
        borderColor: '#d3d4fc',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#7176ff',
      },
    },
  },
})(TextField);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  input: {
    '&::placeholder': {
      color: '#d3d4fc'
    }
  },
  margin: {
    margin: theme.spacing(0),
  },
}));

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Checkout({location}) {
  const [state, setState] = React.useState({})
  const [termsOpened, setTermsOpened] = React.useState(0)

  useEffect(() => {

    setState({
        ...state,
        giveaway: location.state.itemName
    })
  }, []);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleChangeCheck = (e) => {
    setState({ ...state, [e.target.name]: (e.target.checked? "Yes": "") })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Checkout" />
      <div className="columns is-gapless checkout-page-wrapper">
        <div style={{ backgroundImage: `url(${termsOpened ? null : Background})` }} className="column checkout-image is-7-fullhd is-6-widescreen is-5-desktop is-4-tablet">
          {termsOpened ?
            <div className="terms-wrapper">
              <p>Twispay is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you: <strong>I agree to receive useful information from Twispay.</strong></p>
              <br />
              <p>In order to provide you the content requested, we need to store and process your personal data. If you consent to us storing your personal data for this purpose, please tick the checkbox below. <strong>I agree to allow Twispay to store and process my personal data.</strong></p>
              <br />
              <p>You may unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.</p>
              <button href="javascript;" style={{ marginTop: 20, lineHeight: 0 }} onClick={() => setTermsOpened(false)} className="button is-success">OK</button>
            </div> :
            <div className="image-content">
              <p >Submit your details <br />
                securely to complete <br />
                this experience</p>
              <Link className="back-btn inverted" to={`/cart`} ><span className="icon"><i className="fas fa-arrow-left" /></span> <span className="text-btn">Back</span></Link>
            </div>
          }
        </div>
        <div className="column">
          <div className="form-wrapper">
            <form
              name="submissions"
              method="post"
              action="/pay/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="submissions" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
              </p>
              
              <ThemeProvider theme={theme}>
                <div className="columns">
                  <div className="column is-half">
                    <CssTextField
                      className={classes.margin}
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      name="firstname" onChange={handleChange}
                      InputProps={{
                        className: classes.input
                      }}
                      required
                    />
                  </div>
                  <div className="column is-half">
                    <CssTextField
                      className={classes.margin}
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      name="lastname" onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <CssTextField
                      className="classes.margin"
                      fullWidth label="Your email"
                      variant="outlined"
                      type="email"
                      name="email" onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-half">
                    <CssTextField
                      className={classes.margin}
                      fullWidth label="Phone Number"
                      variant="outlined"
                      name="phone" onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="column is-half">
                    <CssTextField
                      className={classes.margin}
                      fullWidth label="Position"
                      variant="outlined"
                      name="position" onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <CssTextField
                      className="classes.margin"
                      fullWidth label="Company Name"
                      variant="outlined"
                      name="company-name" onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <CssTextField
                      className="classes.margin"
                      fullWidth label="Company Website"
                      variant="outlined"
                      name="website" onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column" style={{ padding: 0 }}>
                    <Checkbox
                      checked={state.checkedA}
                      onChange={handleChange}
                      value="Yes"
                      name="agreed-terms"
                      color="primary"
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                      required
                    />
                    <label className='agreement'>Sunt de acord cu prevederile <span onClick={() => setTermsOpened(true)}>Politicii de Confidențialitate</span> <sup style={{color:'tomato'}}>*</sup></label>
                  </div>
                </div>
                <div className="columns">
                  <div className="column" style={{ padding: 0 }}>
                    <p style={{padding: 10}}>
                    Sunt de acord ca Twispay sa ma informeze cu privire la noi servicii și/sau produse,și alte noutăți în legătură cu produsele și serviciile Twispay.
                    </p>
                    <Checkbox
                      checked={state.checkedA}
                      onChange={handleChange}
                      value="Yes"
                      name="agreed-email"
                      color="primary"
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                      required
                    />
                    <label className='agreement'>Email <sup style={{color:'tomato'}}>*</sup></label>
                    <Checkbox
                      checked={state.checkedSMS}
                      onChange={handleChangeCheck}
                      value={state['agreed-sms']}
                      name="agreed-sms"
                      color="primary"
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                      
                    />
                    <label className='agreement'>SMS/Telefonic</label>
                  </div>
                </div>
              </ThemeProvider>
              <input name="giveaway"  defaultValue={state.itemName}  type="hidden"/>
              <button style={{ lineHeight: 0, marginTop: 'auto', position:'absolute', bottom:'2rem', right: '2rem' }} className="button is-success " type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}